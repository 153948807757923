.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-layout-header {
  padding: 0;
  background-color: #fff;
}

.ant-layout-content {
  background-color: #f5f5f8;
}

.ant-layout-footer {
  background-color: #e6e6e6;
  border-top: solid 1px #aaa;
}

.ant-layout-header {
  height: 40px
}

.Menu {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  display: flex;
  justify-content: center;
}

.Page {
  text-align: left;
  margin: 2em auto;
  margin-top: calc(35px + 2em);
  margin-bottom: calc(20px + 2em);
  max-width: calc(1000px - 4em);
  font-size: 18px;
  line-height: 1.5;
  @media (max-width: 500px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.footnote {
  font-size: 14px;
}

.nameTitle {
  text-align: left;
  font-size: 42px;
}

.sectionTitle {
  text-align: left;
  font-size: 32px;
}

.selfImage {
  width: 20%;
  float: left;
  margin-right: 14px;
}

.selfAuthor {
  font-weight: bold;
}

.journalName {
  font-style: italic;
}

.italic {
  font-style: italic;
}

.suppInfo p {
  margin: 0px;
}

.publicationTitle {
  text-align: left;
  margin-top: 28px;
  font-size: 24px;
}

.projectImage {
  width: 80%;
  display: block;
  margin: 0 auto;
}

footer p {
  margin-top: 16px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 14px;
}

footer span {
  margin-right: 25px;
  font-size: 16px;
}

p {
  color: #222;
}
